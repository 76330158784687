import cn from 'classnames'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { getArticleBySlugLoader } from '../data-loaders'
import useDataLoader from '../hooks/useDataLoader'
import useMdastProcessor from '../hooks/useMdastProcessor'
import style from './Article.module.css'

function renderContent (isLoading, error, article, Content) {
  if (isLoading) {
    return (<p>loading...</p>)
  } else if (error) {
    return (<p>oops, something went wrong</p>)
  } else if (article) {
    return (
      <>
        <h1>{article.title}</h1>
        <div className={style.publishDate}>
          {format(new Date(article.date), 'dd MMM yyyy')}
        </div>
        {Content}
      </>
    )
  }

}

export default function Article ({className, slug}) {
  let params = useParams()
  let loader = useMemo(() => getArticleBySlugLoader(params.slug), [params.slug])
  let [isLoading, article, error] = useDataLoader(loader)
  let Content = useMdastProcessor(article && article.mdast)
  return (
    <div className={cn(className, style.article)}>
      {
        renderContent(isLoading, error, article, Content)
      }
    </div>
  )
}