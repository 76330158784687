import styles from './Header.module.css'
import cn from 'classnames'
import { Link } from 'react-router-dom'
import { useContext } from 'react'
import { LoadingContext } from '../context'

export default function Header ({className}) {
  let {isLoading} = useContext(LoadingContext)
  let altComment = "An outline of my face, drawn entirely on Excalidraw. The drawing contains a contour of my head, 2 eyes and a, naturally for me, flat smile. The drawing does not contain a nose, because I could not draw one. I have a nose in real life."
  return (
    <header className={cn(className, styles.header)}>
      <img className={cn(styles.logo, {[styles.spinning]: isLoading})} src="/head.svg" alt='logo' title={altComment}/>
      <nav className={styles.navigation}>
        <ul>
          <li><Link to={`/`}>Blog</Link></li>
        </ul>
      </nav>
    </header>
  )
}