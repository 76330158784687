import { createElement, Fragment, useEffect, useState } from 'react'
import { unified } from 'unified'
import rehypeReact from 'rehype-react'
import { toHast } from 'mdast-util-to-hast'

export default function useProcessor(mdast) {
  const [Content, setContent] = useState(Fragment)

  useEffect(() => {
    if (mdast) {
      let file = unified()
        .data('settings', {fragment: true})
        .use(rehypeReact, { createElement, Fragment })
        .stringify(toHast(mdast))
      setContent(file)
    }
  }, [mdast])

  return Content
}