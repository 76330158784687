import cn from 'classnames'
import { format } from 'date-fns'
import { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { getArticlesLoader } from '../data-loaders'
import useDataLoader from '../hooks/useDataLoader'
import style from './Articles.module.css'

export default function Articles({ className }) {
  let loader = useMemo(() => getArticlesLoader(), [])
  let [isLoading, articlesList, error] = useDataLoader(loader)

  return (
    <div className={cn(className)}>
      <h1>Blog</h1>
      {
        isLoading
          ? (
            <p>loading...</p>
          )
          : null
      }

      {
        !isLoading && !error
        ? (
            <ol className={style.articles}>
              {(articlesList || []).map((article) => (
                <li key={article._id}>
                  <h2><Link to={`/article/${article.slug}`}>{article.title}</Link></h2>
                  <div className={style.publishDate}>
                    {format(new Date(article.date), 'do MMM yyyy')}
                  </div>
                  <div className={style.preview}>
                    <p>
                      {article.summary} <br />
                      <Link to={`/article/${article.slug}`}>Read more...</Link>
                    </p>
                  </div>
                </li>
              ))}
            </ol>
          ) : null
      }
    </div>
  )
}