import { useContext, useEffect, useState, useRef } from "react"
import { LoadingContext } from "../context"

export default function useDataLoader (loader) {
  let loadingContext = useRef(useContext(LoadingContext))

  let [data, setData] = useState(null)
  let [error, setError] = useState(null)

  useEffect(function () {
    loadingContext.current.setIsLoading(true)
    let abortController = new AbortController();
    loader({signal: abortController.signal})
      .then(loadedData => setData(loadedData))
      .catch(loadingError => setError(loadingError))
      .finally(() => {
        loadingContext.current.setIsLoading(false)
      })
    return () => {
      abortController.abort("Component unmount")
    }
  }, [loader, loadingContext])

  return [loadingContext.current.isLoading, data, error]
}