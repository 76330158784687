import styles from './App.module.css';
import Article from './Article/Article';
import Articles from './Articles/Articles';
import Header from './Header/Header';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import { useState } from 'react';
import { LoadingContext } from './context';

function App() {
  let [isLoading, change] = useState(false)
  return (
    <BrowserRouter>
      <div className={styles.app}>
        <LoadingContext.Provider value={{isLoading: isLoading, setIsLoading: (newValue) => change(newValue)}}>
          <Header className={styles.header} />
          <Routes>
            <Route path="/" element={<Articles className={styles.content} />} />
            <Route path="/article/:slug" element={<Article className={styles.content} />} />
          </Routes>
        </LoadingContext.Provider>
      </div>
    </BrowserRouter>
  );
}

export default App;
