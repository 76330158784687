const BLOG_API_ENDPOINT = process.env.REACT_APP_BLOG_API_ENDPOINT

export function getArticlesLoader() {
  return (options) => {
    return fetch(`${BLOG_API_ENDPOINT}/blog-posts`, {...options, mode: 'cors'})
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw response
        }
      })
  }
}

export function getArticleBySlugLoader(slug) {
  return (options) => {
    return fetch(`${BLOG_API_ENDPOINT}/blog-posts/slug/${slug}`, {...options, mode: 'cors'})
      .then(response => {
        if (response.ok) {
          return response.json()
        } else {
          throw response
        }
      })
  }
}